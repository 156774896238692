<template>
    <div>
        <UiSwitch
            :items="tabs"
            :value="current"
            class="match-stat__switch"
            @input="onChangeTab"
        />


        <div v-show="isTabActive(TAB_NAMES.BETS)">
            <PredictionsList
                :interscroller="[3, 6]"
                :native-ad-index="10"
            >
                <template #default="{ item, index }">
                    <PredictionsItem
                        :index="index"
                        :prediction="item"
                        :hide-sponsored="firstSponsoredPredictionIndex !== index"
                    />
                </template>

                <template #zero>
                    {{ $t('zero') }}
                </template>
            </PredictionsList>

            <ShowMoreButton
                v-if="!isAllPredictions"
                class="match-stat__more-predictions-button"
                @click="onShowMoreClick"
            />
        </div>

        <MatchStatBets
            v-show="isTabActive(TAB_NAMES.COEFS)"
            :lines="lines"
            :is-football="false"
            class="bi-block bi-block__body"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "zero": "Прогнозов еще нет. А что думаете вы? Поделитесь своей экспертизой и собирайте подписчиков!",
        "predictions": "Прогнозы",
        "coefs": "Коэффициенты"
    },
    "en": {
        "zero": "No tips yet. What do you think about the match? Post your own tip and get new subscribers!",
        "predictions": "Predictions",
        "coefs": "Odds"
    }
}
</i18n>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

import PredictionsList from '@/components/Predictions/PredictionsList.vue'
import PredictionsItem from '@/components/Predictions/Item/PredictionsItem.vue'
import ShowMoreButton from '@/components/Predictions/PredictionsListLoadMoreButton.vue'
import MatchStatBets from '@/views/match/inner/MatchStatBets.vue'

const LOAD_PREDICTIONS_COUNT = 10

const TAB_NAMES = {
    BETS: 'predictions',
    COEFS: 'coefs'
}

export default {
    name: 'MatchNewSportsStat',

    components: {
        PredictionsList,
        PredictionsItem,
        MatchStatBets,
        ShowMoreButton
    },

    data () {
        return {
            TAB_NAMES,
            isLoadingPredictions: false,
            current: TAB_NAMES.BETS
        }
    },

    computed: {
        ...mapState('newSportsMatch', ['match', 'odds']),
        ...mapGetters('predictions', ['firstSponsoredPredictionIndex', 'isAllPredictions']),
        ...mapState('widgets/teaser', ['teaser']),

        tabs () {
            const tabs = [
                {
                    title: this.$t(TAB_NAMES.BETS),
                    value: TAB_NAMES.BETS
                },
                {
                    title: this.$t(TAB_NAMES.COEFS),
                    value: TAB_NAMES.COEFS,
                    disabled: !this.lines.length
                }
            ]

            return tabs
        },

        lines () {
            return this.odds
        }
    },

    methods: {
        ...mapActions({
            setFilter: 'predictions/filter'
        }),

        isTabActive (name) {
            return this.current === name
        },

        onChangeTab (name) {
            if (name === TAB_NAMES.LINEUPS) {
                this.$pivik.event('match', 'stats_widget', 'squad')
            }

            this.current = name
        },

        async onShowMoreClick () {
            if (this.isLoadingPredictions) {
                return
            }

            this.isLoadingPredictions = true

            try {
                await this.loadMorePredictions()
            } catch (err) {}

            this.isLoadingPredictions = false
        },

        loadMorePredictions () {
            return this.setFilter({
                updates: {
                    count: LOAD_PREDICTIONS_COUNT
                },
                isNewSports: true,
                reset: false
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.match-stat
    +layout-md-and-up()
        padding 1.6rem 2.4rem

    &__switch
        overflow-x auto
        margin-bottom .8rem

        & >>> .ui-switch__item
            margin-bottom .8rem
            white-space nowrap

    &__select
        margin-bottom 1.6rem

    &__stat-block
        margin-bottom 2.4rem

        &-title
            margin-bottom .8rem

    &__more-predictions-button
        margin 1.6rem auto 0

    &__teaser
        margin-top 4rem

</style>
