<template>
    <div class="bi-calendar-line">
        <div
            ref="list"
            class="bi-calendar-line__list"
            :style="listPosition"
        >
            <template v-for="(item, index) in list">
                <a
                    v-if="type === 'month'"
                    :key="index"
                    :class="{
                        'is-active': checkActiveItem(item)
                    }"
                    class="bi-calendar-line__item"
                    @click="select(item, index)"
                >
                    <span
                        :class="checkActiveItem(item) ? 'link-primary-inner' : 'link-text-inner'"
                        class="bi-calendar-line__item-date"
                    >
                        {{ $t(`months-${item.month}`) }}
                    </span>
                    <span class="bi-calendar-line__item-label">
                        {{ item.year }}
                    </span>
                </a>

                <a
                    v-if="type === 'week'"
                    :key="index"
                    :class="{ 'is-active': selectDateInfo.number === item.number }"
                    class="bi-calendar-line__item"
                    @click="select(item, index)"
                >
                    <span class="bi-calendar-line__item-label">
                        <span v-if="item.first.month !== item.last.month">{{ $t(`months-${item.first.month}`) }} — </span>
                        <span>{{ $t(`months-${item.last.month}`) }}</span>
                    </span>
                    <span class="bi-calendar-line__item-date">
                        {{ item.first.day }} - {{ item.last.day }}
                    </span>
                </a>
            </template>
        </div>
    </div>
</template>

<i18n src="@/i18n/time.json"></i18n>

<script>
import {
    dayData, monthData, weekData, nextMonth, prevMonth, nextWeek, prevWeek, numberWeekInYear
} from '@/utils/calendar'

export default {
    name: 'BiCalendarLine',

    props: {
        /**
         * Тип календаря ['month', 'week']
         */
        type: {
            type: String,
            default: 'month',
            validator (value) {
                return ['month', 'week'].includes(value)
            }
        },

        value: {
            type: Number,
            default: Date.now()
        },

        max: {
            type: Number,
            default: Date.now()
        },

        min: {
            type: Number,
            // 7-08-2017
            default: 1502053200000
        }
    },

    data () {
        return {
            list: [],
            listOffset: 0
        }
    },

    computed: {
        selectDateInfo () {
            const data = dayData(this.value)

            data.number = numberWeekInYear(this.value)

            return data
        },

        maxDay () {
            return dayData(this.max)
        },

        minDay () {
            return dayData(this.min)
        },

        listPosition () {
            return {
                transform: `translate3d(${this.listOffset}px, 0, 0)`
            }
        }
    },

    watch: {
        type () {
            this.create()
            this.moveToCenter()
        },

        value () {
            this.moveToCenter()
        }
    },

    created () {
        this.create()
    },

    mounted () {
        this.moveToCenter()
    },

    methods: {
        checkActiveItem (item) {
            return this.selectDateInfo.month === item.month
                && this.selectDateInfo.year === item.year
        },

        create () {
            if (this.type === 'month') {
                this.createMonths()
            } else if (this.type === 'week') {
                this.createWeeks()
            }
        },

        select (date, index) {
            if (index < 10) {
                if (this.type === 'month') {
                    this.prevMonth(10 - index)
                } else if (this.type === 'week') {
                    this.prevWeek(10 - index)
                }
            } else if (index > this.list.length - 11) {
                if (this.type === 'month') {
                    this.nextMonth(10)
                } else if (this.type === 'week') {
                    this.nextWeek(10)
                }
            }

            this.$emit('input', date.timestamp)
        },

        moveToCenter () {
            this.$nextTick(() => {
                const $active = this.$el.querySelector('.is-active')
                const viewWidth = this.$el.clientWidth
                const widthList = this.$refs.list.clientWidth
                const activeOffset = $active.offsetLeft
                const activeWidth = $active.clientWidth
                let offset = widthList - activeOffset - viewWidth / 2 - activeWidth / 2
                const maxOffset = widthList - viewWidth

                offset = Math.max(0, offset)
                offset = Math.min(maxOffset, offset)

                this.listOffset = offset
            })
        },

        createMonths () {
            const month = monthData(this.value)

            this.list = [month]
            this.prevMonth(10)
            this.nextMonth(10)
        },

        createWeeks () {
            const week = weekData(this.value)

            this.list = [week]
            this.prevWeek(10)
            this.nextWeek(10)
        },

        nextMonth (count) {
            for (let i = count; i; i -= 1) {
                const last = this.list[this.list.length - 1]
                const month = nextMonth(last.timestamp)
                const isOverMaxYear = month.year > this.maxDay.year
                const isOverMaxMonth = month.month > this.maxDay.month

                if (isOverMaxYear || (isOverMaxMonth && month.year >= this.maxDay.year)) {
                    break
                }

                this.list.push(month)
            }
        },

        prevMonth (count) {
            for (let i = count; i; i -= 1) {
                const last = this.list[0]
                const month = prevMonth(last.timestamp)
                const isLessMinYear = month.year < this.minDay.year
                const isLessMinMonth = month.month < this.minDay.month

                if (isLessMinYear || (isLessMinMonth && month.year <= this.minDay.year)) {
                    break
                }

                this.list.unshift(month)
            }
        },

        nextWeek (count) {
            for (let i = count; i; i -= 1) {
                const last = this.list[this.list.length - 1]
                const week = nextWeek(last.timestamp)

                if (week.timestamp > this.maxDay.timestamp) {
                    break
                }

                this.list.push(week)
            }
        },

        prevWeek (count) {
            for (let i = count; i; i -= 1) {
                const last = this.list[0]
                const week = prevWeek(last.timestamp)

                if (week.timestamp < this.minDay.timestamp) {
                    break
                }

                this.list.unshift(week)
            }
        }
    }
}
</script>


<style rel="stylesheet/stylus" lang="stylus">
    .bi-calendar-line {
        position relative
        width 100%
        height 6rem
        overflow hidden
        border-bottom .1rem solid $cl-secondary-light
        &::after,
        &::before {
            content ""
            position absolute
            pointer-events none
            z-index 10
            top 0
            bottom 0
            width 0
            box-shadow 0 0 4rem 3rem $cl-white
        }
        &::before {
            left 0
        }
        &::after {
            right 0
        }
        &__list {
            position absolute
            align-items center
            z-index 1
            display flex
            top 0
            bottom 0
            right 0
            transition transform 0.6s ease
            user-select none
        }

        &__item {
            display block
            text-align center
            min-width 10rem
            padding 0 5px
            box-sizing border-box
            cursor pointer

            &-label {
                display block
                font-size 1.2rem
                color #daddde
                font-family $font-family-content
                white-space nowrap
            }
            &-date {
                display block
                font-size $font-size-base-sub
                white-space nowrap
            }
            &:hover {
                text-decoration none
            }
            &:hover &-date{
                text-decoration underline
            }
        }
    }
</style>
