import { render, staticRenderFns } from "./index.vue?vue&type=template&id=10ec4d5c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "../styles.styl?vue&type=style&index=0&prod&rel=stylesheet%2Fstylus&lang=stylus&"
import style1 from "./index.vue?vue&type=style&index=1&id=10ec4d5c&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ec4d5c",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports