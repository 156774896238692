<template>
    <section>
        <UiBreadcrumbs :items="breadcrumbs" />

        <BiPageHeader>
            <h1 class="bi-flex bi-header-4">
                {{ $t('h1') }}
            </h1>
        </BiPageHeader>

        <div class="bi-block">
            <div v-if="getMatches.length">
                <MatchCenterMatch
                    v-for="match in getMatches"
                    :id="match.id"
                    :key="match.id"
                    :tournament-id="tournament.id"
                    :tournament-name="tournament.name"
                    :time="match.time"
                    :minute="match.minute"
                    :status="match.status.id"
                    :status-name="match.status.name"
                    :predictions-count="match.bets_count"
                    :odds="match.oddss"
                    :team-home-name="match.home_team.team.name"
                    :team-home-logo="getHomeTeamLogo(match)"
                    :team-home-score="match.home_team.score"
                    :team-away-name="match.away_team.team.name"
                    :team-away-logo="getAwayTeamLogo(match)"
                    :team-away-score="match.away_team.score"
                    :webname="match.webname"
                    :stadium-city="match.stadium ? match.stadium.city : ''"
                    :stadium-name="match.stadium ? match.stadium.name : ''"
                    :bets-count="match.bets_count"
                />
            </div>
            <TournamentZero
                v-else
                :logo="tournament.logo"
            />
        </div>
    </section>
</template>

<i18n>
{
    "ru": {
        "h1": "Чемпионат Европы по футболу 2020 - Расписание матчей",
        "title": "Расписание Евро 2020 по футболу - календарь матчей UEFA EURO 2021 - Betting Insider",
        "description": "Евро 2020 по футболу: расписание и результаты матчей сегодня и завтра на UEFA EURO 2021.",
        "euro2020": "Евро 2020",
        "schedule": "Матчи",
        "results": "Результаты"
    },
    "en": {
        "h1": "Euro 2020 - Fixtures",
        "title": "UEFA EURO 2020 match schedule, fixtures & result",
        "description": "Euro 2020 - Fixtures, venues, full schedule - Uefa Euro 2021 - Betting Insider",
        "euro2020": "Euro 2020",
        "schedule": "Sсhedule",
        "results": "Results"
    }
}
</i18n>

<script>
import { mapGetters, mapState } from 'vuex'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import MatchCenterMatch from '@/components/MatchCenter/old/MatchCenterMatch.vue'
import TournamentZero from '@/components/Tournament/TournamentZero.vue'
import { getHomeTeamLogo, getAwayTeamLogo } from '@/utils/team-logo'
import { EURO2020_TOURNAMENT_ID } from '@/constants'

export default {
    name: 'Euro2020ScheduleView',

    components: {
        BiPageHeader,
        MatchCenterMatch,
        TournamentZero
    },

    asyncData ({ store }) {
        return store.dispatch('tournaments/fetchTournament', EURO2020_TOURNAMENT_ID)
            .then((tournament) => {
                if (!tournament) {
                    return Promise.reject({ code: 404 })
                }

                store.commit('euro2020/matches/tournament', tournament)

                return store.dispatch('euro2020/matches/fetch', tournament.id)
            })
    },

    head () {
        return {
            title: this.title,
            meta: [
                { id: 'description', name: 'description', content: this.description },
                { id: 'og:title', property: 'og:title', content: this.title },
                { id: 'og:description', property: 'og:description', content: this.description },
                { id: 'twitter:title', property: 'twitter:title', content: this.title },
                { id: 'twitter:description', property: 'twitter:description', content: this.description }
            ]
        }
    },

    computed: {
        ...mapGetters({
            getMatches: 'euro2020/matches/getMatches'
        }),

        ...mapState('euro2020/matches', ['tournament']),

        breadcrumbs () {
            return [
                {
                    title: 'Betting Insider',
                    to: {
                        name: 'home'
                    }
                },
                {
                    title: this.$t('euro2020'),
                    to: {
                        name: 'euro2020'
                    }
                },
                {
                    title: this.$t('schedule')
                }
            ]
        },

        title () {
            return this.$t('title')
        },

        description () {
            return this.$t('description')
        }
    },

    methods: {
        getHomeTeamLogo,
        getAwayTeamLogo
    }
}
</script>

