<template>
    <div class="view-match">
        <UiBreadcrumbs :items="breadcrumbs" />

        <BiPageHeader>
            <h1 class="bi-header-4">
                <span>{{ pageTitleText }}</span>
            </h1>
        </BiPageHeader>

        <div class="bi-block view-match__header">
            <div class="view-match__tournament">
                <span v-if="isLive">
                    <span class="t-color-warn">
                        LIVE {{ match.currentMinute }}'
                    </span>&nbsp; • &nbsp;
                </span>

                <span v-if="isCancelled">
                    {{ $t('cancelled') }}
                </span>

                <router-link
                    v-else-if="hasMatchCenterLink"
                    :to="matchCenterRoute"
                    class="link-info-inner bi-body-2"
                    @click.native="onDateClick"
                >
                    <UiDate
                        :timestamp="match.dateStart"
                        itemprop="startDate"
                        format="bulgakov"
                        class="t-capitalize"
                    />
                </router-link>

                <span v-else>
                    <UiDate
                        :timestamp="match.dateStart"
                        format="bulgakov"
                        class="t-capitalize"
                    />
                </span>

                <template v-if="tournamentName">
                    <span>&nbsp; • &nbsp;</span>
                    <span
                        class="bi-body-2"
                        @click="onTournamentClick"
                    >
                        {{ tournamentName }}
                    </span>
                </template>
            </div>

            <TheMatchBoard
                :home-team-name="homeTeam.name"
                :home-team-score="match.result.homeScore"

                :away-team-name="awayTeam.name"
                :away-team-score="match.result.awayScore"

                :status-id="match.state"
                :status-name="$t('finished')"

                :start="startTime"
                :minute="match.currentMinute"

                :win-type="match.result.winType"
                :winner-type="match.result.winnerType"

                :is-football="false"
            />

            <BetList
                v-if="matchBetLine.length"
                v-slot="{ item, index }"
                :list="matchBetLine"
            >
                <BetButton
                    :active="selectBet.id === item.id"
                    :rate="item.rate"
                    :bet-name="item.name"
                    class="view-match__bet"
                    @click.native="onHeaderSetBet(item, index)"
                />
            </BetList>
        </div>

        <DFPPromoBlockHeader
            place="match-center"
            class="mt-20"
        />

        <!-- Виджет статистики матча -->
        <MatchNewSportsStat class="mt-20" />
        <!-- виджет статистики матча -->
    </div>
</template>

<i18n>
{
    "ru": {
        "title": "%{homeTeam} - %{awayTeam}: прогноз на матч %{date}, «%{tournament}» | Betting Insider",

        "titleForID": "%{homeTeam} - %{awayTeam} - прогноз на матч %{date}: %{tournament}",

        "description": "Ставки и прогнозы на матч %{tournament} %{homeTeam} — %{awayTeam} от пользователей и экспертов, предматчевый анализ, статистика, коэффициенты на Betting Insider.",
        "page-title": "%{homeTeam} — %{awayTeam} прогнозы на матч %{date}",
        "microdata-description": "%{homeTeam} — %{awayTeam}: прогнозы и ставки на матч",
        "cancelled": "Отменен",
        "stat": "Статистика",
        "match-center": "Матч-центр",
        "match": "Матч",
        "separator": "—",
        "finished": "Завершен"
    },
    "en": {
    }
}
</i18n>

<script>
import { mapState, mapActions } from 'vuex'
import betMixin from '@/mixins/bet'

import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import BetList from '@/components/Bet/BetList.vue'
import BetButton from '@/components/Bet/BetButton.vue'
import DFPPromoBlockHeader from '@/components/DFP/DFPPromoBlockHeader.vue'
import PredictionsList from '@/components/Predictions/PredictionsList.vue'
import isMcAvailableForDate from '@/utils/is-mc-available-for-date'
import TheMatchBoard from '@/components/Match/MatchBoard/MatchBoard.vue'

import MatchNewSportsStat from '../inner/MatchNewSportsStat.vue'
import meta from '../meta'

const START_PREDICTIONS_COUNT = 5

const MATCH_UPDATE_INTERVAL_MS = 20000

export default {
    name: 'MatchView',

    components: {
        BiPageHeader,
        TheMatchBoard,
        BetList,
        BetButton,
        DFPPromoBlockHeader,
        MatchNewSportsStat
    },

    mixins: [betMixin, meta],

    asyncData ({ store, route, isServer }) {
        const matchId = route.params.id
        const sportId = route.params.sport

        store.dispatch('newSports/setSelectedSportId', sportId)

        return Promise.all([
            store.dispatch('newSportsMatch/fetch', { id: matchId }),
            PredictionsList.loadPredictions(store, {
                isNewSports: true,
                reset: true,
                updates: {
                    matchId,
                    count: START_PREDICTIONS_COUNT
                }
            })
        ])
            .then(([match]) => {
                if (match.sport.code !== sportId) {
                    return Promise.reject({ code: 404 })
                }

                if (isServer) {
                    store.dispatch('match/setMatchCacheControl', { matchId })
                }
            })
    },

    data () {
        return {
            interval: false
        }
    },

    computed: {
        ...mapState('newSportsMatch', ['match']),

        isLive () {
            return this.match.state === 'LIVE'
        },

        sportId () {
            return this.match.sport.code
        },

        homeTeam () {
            return this.match.homeTeam?.team || {}
        },

        awayTeam () {
            return this.match.awayTeam?.team || {}
        },

        startTime () {
            return this.match.dateStart
        },

        pageTitleText () {
            return this.$t('page-title', {
                homeTeam: this.homeTeam.name,
                awayTeam: this.awayTeam.name,
                date: this.date
            })
        },

        matchTitle () {
            return `${this.homeTeam.name} ${this.$t('separator')} ${this.awayTeam.name}`
        },

        date () {
            return this.$date.transform(this.startTime * 1000, 'date')
        },

        tournament () {
            return this.match.tournament || {}
        },

        tournamentName () {
            return this.tournament.customName || this.tournament.name
        },

        breadcrumbs () {
            const list = [
                {
                    title: 'Betting Insider',
                    to: {
                        name: 'home'
                    }
                },
                {
                    title: this.$t('match-center'),
                    to: {
                        name: 'match-center',
                        params: {
                            sport: this.sportId
                        }
                    }
                },
                {
                    title: `${this.$t('match')} ${this.matchTitle}`,
                    to: ''
                }
            ]

            return list
        },

        matchStartDate () {
            return new Date(this.startTime * 1000)
        },

        hasMatchCenterLink () {
            return this.isMcAvailableForDate(this.matchStartDate)
        },

        matchCenterRoute () {
            let day = this.matchStartDate.getDate()
            let month = this.matchStartDate.getMonth() + 1

            if (month < 10) {
                month = `0${month}`
            }

            if (day < 10) {
                day = `0${day}`
            }

            return {
                name: 'match-center',
                params: {
                    date: `${this.matchStartDate.getFullYear()}-${month}-${day}`,
                    sport: this.sportId
                }
            }
        },

        matchBetLine () {
            return this.match.odds || []
        },

        isCancelled () {
            return this.match.state === 'STALE'
        }
    },

    mounted () {
        if (this.isLive) {
            this.interval = setInterval(() => this.update(), MATCH_UPDATE_INTERVAL_MS)
        }
    },

    beforeDestroy () {
        clearInterval(this.interval)
    },

    methods: {
        ...mapActions({
            fetchMatch: 'newSportsMatch/fetch'
        }),

        isMcAvailableForDate,

        onHeaderSetBet (bet, index) {
            this.$pivik.event('match', 'click_coeff', index)

            this.onBet({
                isFootball: false,
                ...bet
            })
        },

        onDateClick () {
            const date = this.$date.transform(this.startTime * 1000, 'date')

            this.$pivik.event('match', 'click_date', date)
        },

        onTournamentClick () {
            this.$pivik.event('match', 'click_tournament', this.tournament.id)
        },

        update () {
            const matchId = this.match.id

            if (matchId) {
                this.fetchMatch({ id: matchId })
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.view-match
    &__title
        display flex
        align-items center
        justify-content center
        margin-bottom .8rem

    &__header
        padding 3.2rem 1.8rem 1.6rem

        +layout-sm()
            padding 1.6rem .8rem .8rem

    &__bet
        +layout-sm-and-up()
            height 3.2rem
            padding 0 1.4rem
            line-height @height

    &__tournament
        color $cl-text
        text-align center
        table-layout fixed
        font-size 1.4rem
        line-height 1.9rem
        width 100%
        display table

        & > div
            white-space nowrap
            display table-cell
            text-overflow ellipsis
            overflow hidden

</style>
