<template>
    <div>
        <UiBreadcrumbs :items="breadcrumbs" />

        <SportSelectorDisclaimer
            class="stars-info__new-sports-disclaimer"
        />

        <UiTextRoll
            :max-height="17"
            class="mt-20 mb-20"
            center
            @expand="onExpand"
        >
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="$t('seo-text')" />

            <h2 class="stars-info__header">
                {{ $t('seo-header') }}
            </h2>

            <ul
                class="stars-info__stars"
                v-html="$t('seo-experts')"
            />
            <!-- eslint-enable vue/no-v-html -->
        </UiTextRoll>

        <BiPageHeader>
            <h1 class="bi-header-4">
                {{ $t('t-h1') }}
            </h1>
        </BiPageHeader>

        <DFPPromoBlockHeader
            place="rating"
            class="stars__dfp"
        />

        <ErrorPlaceholder
            v-if="hasError"
            :error="$t('error-text')"
            class="stars__error"
        />

        <RatingTable
            v-else
            :list="filteredList"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "t-title": "Рейтинг лучших капперов и экспертов России и мира - прогнозы Генича, Черданцева, Уткина и других экспертов",
        "t-h1": "Рейтинг экспертов",
        "t-ranking-tipster": "Рейтинг капперов",
        "t-description": "Статистика лучших капперов на ставках среди экспертов, рейтинг капперов - Генича, Черданцева, Уткина, Казанского, Ловчева, Андронова и других экспертов Betting Insider.",
        "t-breadcrumbs-prediction": "Прогнозы на футбол",
        "t-list-empty": "Пока у нас недостаточно данных, чтобы сформировать рейтинг. Попробуйте позже.",
        "error-text": "Что-то пошло не так, рейтинг не загрузился",
        "seo-header": "Рейтинг лучших экспертов-прогнозистов Betting Insider",
        "seo-experts": "<li>Генич – комментатор «Матч ТВ», регулярно оставляет прогнозы для Winline и Рейтинга Букмекеров. Специалист по испанскому футболу.</li><li>Черданцев – комментатор «Матч ТВ», составляет прогнозы для БК Олимп. Легенда беттинг-сообщества. Часто работает правило – «посмотри прогноз Черданцева и поставь наоборот».</li><li>Уткин – российский комментатор, работающий в барах, экспериментирует со своим Youtube-каналом.</li><li>Андронов – спортивный журналист, комментатор. Одно из первых лиц «Советского спорта». Сотрудничает с Рейтингом Букмекеров.</li><li>Казанский – комментатор «Матч ТВ», готовит прогнозы для Рейтинга Букмекеров и Winline.</li><li>Мерсон – эксперт британского Sky Sport. Специалист по английскому футболу.</li><li>Ловчев – футбольный эксперт. Регулярно готовит прогнозы для Рейтинга Букмекеров.</li><li>Шнякин – комментатор «Матч ТВ», сотрудничает с каналом Ставка TV.</li><li>Гусев – комментатор Первого канала. Работает на матчах футбольной и хоккейной сборных, готовит прогнозы для Ставка TV.</li><li class=\"stars-info__last\">… и другие эксперты — в новом рейтинге Betting Insider.</li>",
        "seo-text": "<p>Теперь все желающие смогут изучить наш рейтинг экспертов, бесплатно и без смс!:) А если серьезно, то интересно подсмотреть за звездами, которые пишут для спортивных сайтов, выступают на ТВ. Многие из них категоричны в прогнозах и оценках, поэтому хочется понять их логику.</p><p>В нашем рейтинге прогнозистов представлены футбольные комментаторы, ТВ-эксперты из России и других стран. Теперь можно сравнить точность «аналитики» Черданцева и Уткина на длинной дистанции.</p><p>Мы собрали прогнозы от экспертов на футбол в одном месте. Специалисты работают со ставочными платформами, оставляют прогнозы в ходе аналитических программ – теперь все это собрано на сайте Betting Insider. Ну, а наш рейтинг отсортирует экспертов по значению ROI – их показателю успешности в прогнозировании.</p><p>Теперь лучшие капперы России и мира собраны в одном месте – можно следить, как за рейтингом лучших прогнозистов проекта, так и за рейтингом футбольных экспертов.</p>"
    },
    "en": {
        "t-title": "",
        "t-h1": "",
        "t-ranking-tipster": "",
        "t-description": "",
        "t-breadcrumbs-prediction": "",
        "t-list-empty": "",
        "error-text": "",
        "seo-header": "",
        "seo-experts": "",
        "seo-text": ""
    }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import { DEFAULT_LOCALE } from '@/constants'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import DFPPromoBlockHeader from '@/components/DFP/DFPPromoBlockHeader.vue'
import ErrorPlaceholder from '@/components/common/ErrorPlaceholder/ErrorPlaceholder.vue'
import SportSelectorDisclaimer from '@/components/common/SportSelector/SportSelectorDisclaimer.vue'
import RatingTable from '@/components/Rating/RatingTable.vue'
import ratingStore from '@/store/modules/rating'

const FILTER = {
    type: 'EXPERTS'
}

export default {
    name: 'RatingStarsView',

    components: {
        BiPageHeader,
        DFPPromoBlockHeader,
        ErrorPlaceholder,
        SportSelectorDisclaimer,
        RatingTable
    },

    storeModules: {
        rating: ratingStore
    },

    asyncData ({ store }) {
        if (DEFAULT_LOCALE !== 'ru') {
            return Promise.reject({ code: 404 })
        }

        if (store.getters['rating/list'](FILTER)) {
            return Promise.resolve()
        }

        return Promise.all([
            RatingTable.loadRating(store, { filter: FILTER }),
            store.dispatch('rating/contests/loadMenu')
        ])
    },

    data () {
        return {
            filter: FILTER
        }
    },

    head () {
        return {
            title: this.$t('t-title'),
            meta: [
                { id: 'description', name: 'description', content: this.$t('t-description') },
                { id: 'og:title', property: 'og:title', content: this.$t('t-title') },
                { id: 'og:description', property: 'og:description', href: this.$t('t-description') }
            ]
        }
    },

    computed: {
        ...mapGetters({
            ratingList: 'rating/list',
            ratingHasError: 'rating/hasError'
        }),

        filteredList () {
            return this.ratingList(this.filter)
        },

        hasError () {
            return this.ratingHasError(this.filter)
        },

        breadcrumbs () {
            return [
                {
                    title: 'Betting Insider',
                    to: {
                        name: 'home'
                    }
                },
                {
                    title: this.$t('t-breadcrumbs-prediction'),
                    to: {
                        name: 'predictions',
                        params: {
                            sport: 'football'
                        }
                    }
                },
                {
                    title: this.$t('t-ranking-tipster'),
                    to: {
                        name: 'rating'
                    }
                },
                {
                    title: this.$t('t-h1')
                }
            ]
        }
    },

    methods: {
        onExpand () {
            this.$pivik.event('rating', 'text', 'expand')
        }
    }
}
</script>

<style lang="stylus" scoped>
.stars-info
    &__header
        display flex
        justify-content center
        margin-top 2.3rem

    &__new-sports-disclaimer
        margin-top 1.6rem

    &__stars >>> .stars-info__last
        margin-top 2.3rem
        list-style-type none

.stars
    &__dfp
        margin-bottom 1.2rem

        +layout-xs()
            margin-bottom .8rem

    &__error
        max-height 20rem
</style>
