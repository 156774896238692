<template>
    <core-media :mq="['xxs', 'xs', 'sm']">
        <DFPMobileBranding />
    </core-media>
</template>

<script>
import DFPMobileBranding from '@/components/DFP/DFPMobileBranding.vue'

export default {
    name: 'ProfileLayoutTopView',

    components: {
        DFPMobileBranding
    }
}
</script>
