<template>
    <section class="mobile-branding">
        <DFP
            placement="mobile"
            overflow
            :refresh-timer="20"
            @receive="showCreative"
            @render="renderHandler"
        />

        <div
            v-if="creative.disclaimer"
            class="mobile-branding__disclaimer-wrapper"
        >
            <AdDisclaimer
                v-if="creative.disclaimer"
                :text="creative.disclaimer"
            />
        </div>

        <a
            v-if="creative.show"
            :href="creative.link"
            target="_blank"
            @click="creativeClickHandler"
        >
            <img
                :src="creative.image"
                :alt="creative.bookmaker"
                class="mobile-branding__image"
            >
        </a>
    </section>
</template>

<script>
import DFP from '@/components/DFP/DFP.vue'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'

export default {
    name: 'DFPMobileBranding',

    components: {
        DFP,
        AdDisclaimer
    },

    data () {
        return {
            creative: {
                show: false
            }
        }
    },

    methods: {
        showCreative (message) {
            if (message) {
                this.creative.link = message.link
                this.creative.image = message.imageSrc
                this.creative.bookmaker = message.bookmakerName || 'unknown'
                this.creative.disclaimer = message.disclaimer
                this.creative.show = true
                this.$pivik.event('dfp_show', 'MobileBranding_BI', this.creative.bookmaker)
            }
        },

        creativeClickHandler () {
            this.$pivik.event('dfp_click', 'MobileBranding_BI', this.creative.bookmaker)
        },

        renderHandler () {
            this.creative = {
                link: '',
                image: '',
                bookmaker: '',
                disclaimer: '',
                show: false
            }
        }
    }

}
</script>

<style lang="stylus">
.mobile-branding
    position relative
    overflow hidden

    &__image
        display block
        height 20rem
        width auto
        margin-left 50%
        transform translateX(-50%)

    &__disclaimer-wrapper
        position absolute
        left 1rem
        top 1rem
        border-radius $radius-block
        background rgba(255, 255, 255, .95)
        width 3.6rem
        height 3.6rem
        padding .6rem
        box-sizing border-box
        z-index 2
        font-size 0
</style>
