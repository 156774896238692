<template>
    <div class="view-error-404">
        <div class="view-error view-error-404__body">
            <span class="view-error__text">{{ isItContest ? $t('contest-text') : $t('text') }}</span>
            <router-link
                :to="{ name: 'home' }"
                class="view-error-404__btn"
            >
                {{ isItContest ? $t('contest-text-back') : $t('back') }}
            </router-link>

            <div class="view-error__images">
                <span
                    class="view-error__bg view-error-404__bg-1"
                    :style="[position, svgMask]"
                />
                <span
                    class="view-error__bg view-error-404__bg-2"
                    :style="[position2, svgMask]"
                />
            </div>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "title": "Страница не найдена",
        "text": "Вы потерялись?",
        "contest-text": "Конкурсы доступны только в приложении",
        "contest-text-back": "Перейти на главную",
        "back": "Вернуться к началу"
    },
    "en": {
        "title": "Page not found",
        "text": "Lost?",
        "contest-text": "Contests are only available in the app",
        "contest-text-back": "Back",
        "back": "Back"
    }
}
</i18n>

<script>
export default {
    name: 'Errors404View',
    props: {
        isItContest: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            svgMask: {},
            x: 100,
            y: 100
        }
    },

    head () {
        return {
            title: this.title,
            meta: [
                { id: 'description' },
                { id: 'og:title' },
                { id: 'og:description' },
                { id: 'og:image' },
                { id: 'og:image:type' },
                { id: 'og:image:width' },
                { id: 'og:image:height' },
                { id: 'og:url' },
                { id: 'twitter:site' },
                { id: 'twitter:url' }
            ],

            link: [
                { id: 'canonical' },
                { id: 'alternate' },
                { id: 'image_src' }
            ]
        }
    },

    computed: {
        title () {
            return this.$t('title')
        },

        position () {
            const offset = 4
            const x = this.x - offset
            const y = this.y - offset

            return {
                backgroundPosition: `${x}% ${y}%`
            }
        },

        position2 () {
            const offset = 8
            const x = this.x - offset
            const y = this.y - offset

            return {
                backgroundPosition: `${x}% ${y}%`
            }
        }
    },

    created () {
        const svg = '<svg width="815" height="424" viewBox="0 0 815 424" xmlns="http://www.w3.org/2000/svg"><path d="M247.062 417.269H118.76V323.51H0v-82.62L138.9  4.326h108.162v196.17h42.311v123.014h-42.311v93.758zm24.406-205.75c0-33.638 2.35-62.719 7.18-87.397 5.092-26.027 12.81-48.009 23.541-65.964 11.681-19.543 26.818-34.562 45.293-44.285C365.168 4.564 385.022 0 406.538 0c22.352 0 42.687 4.494 60.563 13.78 18.788 9.76 34.06 24.914 45.63 44.649 10.577 18.043 18.028 40.103 22.756 66.202 4.438 24.5 6.602 53.412 6.602 86.888 0 34.023-2.441 63.41-7.46 88.32-5.26 26.116-13.043 48.103-23.742 66.003-11.68 19.543-26.817 34.562-45.292 44.285-17.687 9.309-37.54 13.873-59.057 13.873-22.479 0-42.939-4.635-60.896-14.196-18.86-10.043-34.049-25.59-45.393-45.783-10.211-18.175-17.487-40.197-22.178-66.095-4.438-24.493-6.603-53.248-6.603-86.407zm501.22 205.75H644.388V323.51h-118.76v-82.62l138.9-236.564h108.162v196.17H815v123.014h-42.311v93.758z"></path></svg>'
        const url = `data:image/svg+xml;charset=US-ASCII,${encodeURIComponent(svg)}`

        this.svgMask = {
            mask: `no-repeat top right/100% 100% url('${url}')`,
            '-webkit-mask': `no-repeat top right/100% 100% url('${url}')`
        }
    },

    mounted () {
        document.addEventListener('mousemove', this.onMouseMove)
    },

    beforeDestroy () {
        document.removeEventListener('mousemove', this.onMouseMove)
    },

    methods: {
        onMouseMove (e) {
            const displayMaxH = window.innerHeight
            const displayMaxW = window.innerWidth

            this.x = (e.pageX * 100) / displayMaxW
            this.y = (e.pageY * 100) / displayMaxH
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" src="../styles.styl"></style>

<style lang="stylus" scoped>
.view-error-404
    &__body.view-error
        min-height calc(100vh - 140px)

    &__bg-1
        z-index 1
        background-image url('../../../assets/images/errors/404bg.jpg')
        background-size 104% 104%

    &__bg-2
        z-index 2
        background-image url('../../../assets/images/errors/404bg2.png')
        background-size 108% 108%

    &__btn
        button(6, 'outlined', 'primary')
        margin-bottom 2.4rem

</style>
