<template>
    <div class="view-imaginarium-main">
        <span class="view-imaginarium-main__text">{{ $t('t-footer') }}</span>

        <span class="view-imaginarium-main__win">
            <span>{{ $t('t-win') }}</span>
        </span>
    </div>
</template>

<i18n>
{
    "ru": {
        "t-win": "Ставка сыграла",
        "t-footer": "Выигрывай с профи"
    },
    "en": {
        "t-win": "You win",
        "t-footer": "Win with the pros"
    }
}
</i18n>

<script>
export default {
    name: 'I9mMainView',

    created () {
        this.$store.dispatch('page/setMountOff')
    }
}
</script>

<style lang="stylus" scoped>
    .view-imaginarium-main
        position relative
        margin 0 auto
        min-height 630px
        max-width 1200px
        overflow hidden
        color #fff
        background $cl-text-dark url('@/assets/images/imaginarium/main_share_img.jpg')
        background-position top center

        &__text
            position absolute
            top 425px
            left 0
            right 0
            display block
            text-align center
            font-size 4.0rem
            font-weight $font-weight-bold
            text-transform uppercase

        &__win
            position absolute
            top 84px
            left -96px
            min-width 440px
            height 110px
            line-height 110px
            text-align center
            font-size 5rem
            letter-spacing 4px
            font-weight $font-weight-bold
            text-transform uppercase
            transform rotate(-45deg)
            background-repeat no-repeat
            background-size 100% 110px
            background-image url('@/assets/images/imaginarium/frame_green.png')

            span
                color transparent
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                background-image url('@/assets/images/imaginarium/gb_green_white.png')
</style>
