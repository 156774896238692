<template>
    <div>
        <core-media :mq="['xxs', 'xs', 'sm']">
            <DFPMobileBranding />
        </core-media>
    </div>
</template>

<script>
import DFPMobileBranding from '@/components/DFP/DFPMobileBranding.vue'

export default {
    name: 'MatchTopView',

    components: {
        DFPMobileBranding
    }
}
</script>
